import React from "react";

export const SectionLineLeft = () =>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 50" preserveAspectRatio="none">
        <path className="fill-white" d="M0,5V0h1000v50L0,6z"></path>
    </svg>

export const SectionLineRight = () =>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 50" preserveAspectRatio="none">
        <path className="fill-white" d="M0,0V50h0v0L1000,0z"></path>
    </svg>

export const SectionLineLeftInverted = () =>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 50" preserveAspectRatio="none" className="bg-white">
        <path className="fill-brand-primary" d="M0,5V0h1000v50L0,6z"></path>
    </svg>